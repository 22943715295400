var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Login"}},[_c('div',{staticClass:"MainContainer"},[_c('div',{staticClass:"loginForm"},[_vm._m(0),_c('div',{staticClass:"row LoginTitleRow"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")]),(_vm.type === 'login')?_c('el-form',{ref:"loginForm",staticClass:"formCSS",attrs:{"model":_vm.loginFormData,"hide-required-asterisk":true,"label-width":"100px","label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"email","label":"帐号 (邮箱地址)","rules":[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] } ]}},[_c('el-input',{model:{value:(_vm.loginFormData.email),callback:function ($$v) {_vm.$set(_vm.loginFormData, "email", $$v)},expression:"loginFormData.email"}})],1),_c('el-form-item',{staticClass:"PWDBlock",attrs:{"label":"密码","prop":"password","rules":[{ required: true, message: '请输入密码', trigger: 'blur' }]}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.loginFormData.password),callback:function ($$v) {_vm.$set(_vm.loginFormData, "password", $$v)},expression:"loginFormData.password"}}),_c('div',{staticClass:"forgetPWD",on:{"click":function($event){return _vm.goForgotPWDHandler()}}},[_vm._v("忘记密码？")])],1),_c('el-form-item',{staticClass:"isAlwaysLogin"},[_c('el-checkbox',{on:{"change":_vm.rememberLoginChangeHandler},model:{value:(_vm.checkAlwayLogin),callback:function ($$v) {_vm.checkAlwayLogin=$$v},expression:"checkAlwayLogin"}},[_vm._v("记住登录状态。")])],1),_c('el-form-item',[_c('div',{staticClass:"LoginBtn",on:{"click":function($event){return _vm.submitForm('loginForm')}}},[_vm._v("登   录 ")]),_c('div',{staticClass:"infoBlock"},[_c('div',{staticClass:"itemRow"},[_c('div',{staticClass:"grayBlock"})]),_c('div',{staticClass:"itemRow"},[_vm._v(_vm._s(_vm.appTitle)+"的新用户？")]),_c('div',{staticClass:"itemRow"},[_c('div',{staticClass:"grayBlock"})])]),_c('div',{staticClass:"createAccountBtn",on:{"click":function($event){return _vm.goCreateAccountHandler()}}},[_vm._v("创建您的"+_vm._s(_vm.appTitle)+"帐户 ")])])],1):_vm._e(),(_vm.type === 'reg')?_c('el-form',{ref:"regForm",staticClass:"formCSS",attrs:{"model":_vm.regFormData,"hide-required-asterisk":true,"label-width":"100px","label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"email","label":"帐号 (邮箱地址)","rules":[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] } ]}},[_c('el-input',{model:{value:(_vm.regFormData.email),callback:function ($$v) {_vm.$set(_vm.regFormData, "email", $$v)},expression:"regFormData.email"}})],1),_c('el-form-item',{staticClass:"PWDBlock",attrs:{"label":"密码","prop":"password","rules":[{ required: true, message: '请输入密码', trigger: 'blur' }]}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.regFormData.password),callback:function ($$v) {_vm.$set(_vm.regFormData, "password", $$v)},expression:"regFormData.password"}})],1),_c('el-form-item',{staticClass:"PWDBlock",attrs:{"label":"请重新输入密码","prop":"checkPassword","rules":[
            { required: true, message: '请重新输入密码', trigger: 'blur' },
            { validator: _vm.validateCheckPass, trigger: 'blur' } ]}},[_c('el-input',{attrs:{"type":"password","autocomplete":"off"},model:{value:(_vm.regFormData.checkPassword),callback:function ($$v) {_vm.$set(_vm.regFormData, "checkPassword", $$v)},expression:"regFormData.checkPassword"}})],1),_c('el-form-item',[_c('div',{staticClass:"LoginBtn",on:{"click":function($event){return _vm.submitForm('regForm')}}},[_vm._v("创建您的帐户 ")]),_c('div',{staticClass:"RegInfoBlock"},[_c('div',{staticClass:"infoText"},[_vm._v(" 创建帐户，即视为您同意遵守"+_vm._s(_vm.appTitle)+"的服务条款。 ")]),_c('div',{staticClass:"lineBlock"}),_c('div',{staticClass:"tipBlock",on:{"click":function($event){return _vm.goLoginHandler()}}},[_vm._v(" 已拥有帐户？ 登录 ")])])])],1):_vm._e(),(_vm.type === 'forgot')?_c('el-form',{ref:"forgotForm",staticClass:"formCSS",attrs:{"model":_vm.forgotFormData,"hide-required-asterisk":true,"label-width":"100px","label-position":"top"}},[_c('el-form-item',{attrs:{"prop":"email","label":"帐号 (邮箱地址)","rules":[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] } ]}},[_c('el-input',{model:{value:(_vm.forgotFormData.email),callback:function ($$v) {_vm.$set(_vm.forgotFormData, "email", $$v)},expression:"forgotFormData.email"}})],1),_c('el-form-item',[_c('div',{staticClass:"LoginBtn",on:{"click":function($event){return _vm.submitForm('forgotForm')}}},[_vm._v("继  续 ")])])],1):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row LogoRow"},[_c('img',{attrs:{"src":require("@/assets/base/logo.png"),"alt":""}})])}]

export { render, staticRenderFns }
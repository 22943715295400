<template>
  <div id="Login">
    <div class="MainContainer">
      <div class="loginForm">
        <div class="row LogoRow">
          <img src="@/assets/base/logo.png" alt="" />
        </div>
        <div class="row LoginTitleRow"> {{ titleText }} </div>

        <!-- 登入面板 -->
        <el-form
          v-if="type === 'login'"
          :model="loginFormData"
          :hide-required-asterisk="true"
          ref="loginForm"
          label-width="100px"
          class="formCSS"
          label-position="top"
        >
          <el-form-item
            prop="email"
            label="帐号 (邮箱地址)"
            :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
            ]"
          >
            <el-input v-model="loginFormData.email"></el-input>
          </el-form-item>

          <el-form-item
            label="密码"
            prop="password"
            class="PWDBlock"
            :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]"
          >
            <el-input
              type="password"
              v-model="loginFormData.password"
              autocomplete="off"
            ></el-input>
            <div class="forgetPWD" @click="goForgotPWDHandler()">忘记密码？</div>
          </el-form-item>

          <el-form-item class="isAlwaysLogin">
            <el-checkbox v-model="checkAlwayLogin" @change="rememberLoginChangeHandler"
              >记住登录状态。</el-checkbox
            >
          </el-form-item>

          <el-form-item>
            <div class="LoginBtn" @click="submitForm('loginForm')">登 &nbsp;&nbsp;录 </div>
            <div class="infoBlock">
              <div class="itemRow">
                <div class="grayBlock"></div>
              </div>
              <div class="itemRow">{{ appTitle }}的新用户？</div>
              <div class="itemRow">
                <div class="grayBlock"></div>
              </div>
            </div>
            <div class="createAccountBtn" @click="goCreateAccountHandler()">创建您的{{ appTitle }}帐户 </div>
          </el-form-item>
        </el-form>

        <!-- 註冊面板 -->
        <el-form
          v-if="type === 'reg'"
          :model="regFormData"
          :hide-required-asterisk="true"
          ref="regForm"
          label-width="100px"
          class="formCSS"
          label-position="top"
        >
          <el-form-item
            prop="email"
            label="帐号 (邮箱地址)"
            :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
            ]"
          >
            <el-input v-model="regFormData.email"></el-input>
          </el-form-item>

          <el-form-item
            label="密码"
            prop="password"
            class="PWDBlock"
            :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]"
          >
            <el-input type="password" v-model="regFormData.password" autocomplete="off"></el-input>
          </el-form-item>

          <el-form-item
            label="请重新输入密码"
            prop="checkPassword"
            class="PWDBlock"
            :rules="[
              { required: true, message: '请重新输入密码', trigger: 'blur' },
              { validator: validateCheckPass, trigger: 'blur' },
            ]"
          >
            <el-input
              type="password"
              v-model="regFormData.checkPassword"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <div class="LoginBtn" @click="submitForm('regForm')">创建您的帐户 </div>
            <div class="RegInfoBlock">
              <div class="infoText"> 创建帐户，即视为您同意遵守{{ appTitle }}的服务条款。 </div>
              <div class="lineBlock"></div>
              <div class="tipBlock" @click="goLoginHandler()"> 已拥有帐户？ 登录 </div>
            </div>
          </el-form-item>
        </el-form>

        <!-- 密碼幫助 -->
        <el-form
          v-if="type === 'forgot'"
          :model="forgotFormData"
          :hide-required-asterisk="true"
          ref="forgotForm"
          label-width="100px"
          class="formCSS"
          label-position="top"
        >
          <el-form-item
            prop="email"
            label="帐号 (邮箱地址)"
            :rules="[
              { required: true, message: '请输入邮箱地址', trigger: 'blur' },
              { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] },
            ]"
          >
            <el-input v-model="forgotFormData.email"></el-input>
          </el-form-item>

          <el-form-item>
            <div class="LoginBtn" @click="submitForm('forgotForm')">继&nbsp;&nbsp;续 </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Login',
  data() {
    return {
      checkAlwayLogin: this.$store.state.isRememberLogin,
      type: 'login',
      loginFormData: {
        email: '',
        password: '',
      },
      regFormData: {
        email: '',
        password: '',
        checkPassword: '',
      },
      forgotFormData: {
        email: '',
      },
    };
  },
  watch: {
    '$store.state.isHeaderClickLogin'() {
      this.goLoginHandler();
    },
  },
  computed: {
    ...mapGetters(['appTitle']),
    titleText() {
      if (this.type === 'login') {
        return '登录';
      } else if (this.type === 'reg') {
        return '创建帐户';
      } else {
        return '密码帮助';
      }
    }
  },
  methods: {
    rememberLoginChangeHandler(param) {
      this.$store.commit('setIsRememberLogin', param);
    },
    clearAllFormData() {
      this.loginFormData.email = '';
      this.loginFormData.password = '';

      this.regFormData.email = '';
      this.regFormData.password = '';
      this.regFormData.checkPassword = '';

      this.forgotFormData.email = '';
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.commit('setLoading', true);
          if (formName === 'regForm') {
            this.regFormSubmitHandler();
          } else if (formName === 'loginForm') {
            this.loginFormSubmitHandler();
          } else if (formName === 'forgotForm') {
            this.forgotFormSubmitHandler();
          }
        } else {
          return false;
        }
      });
    },
    goForgotPWDHandler() {
      this.type = '';
      this.clearAllFormData();
      this.$nextTick(() => {
        this.type = 'forgot';
      });
    },
    goCreateAccountHandler() {
      this.type = '';
      this.clearAllFormData();
      this.$nextTick(() => {
        this.type = 'reg';
      });
    },
    goLoginHandler() {
      this.type = '';
      this.clearAllFormData();
      this.$nextTick(() => {
        this.type = 'login';
      });
    },
    validateCheckPass(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regFormData.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    regFormSubmitHandler() {
      setTimeout(() => {
        this.$store
          .dispatch('addAccount', {
            mail: this.regFormData.email,
            password: this.regFormData.password,
          })
          .then(() => {
            this.$notify({
              title: '注册成功',
              message: '請重新登入',
              type: 'success',
            });
            this.goLoginHandler();
          })
          .catch(() => {
            this.$notify.error({
              title: '注册失败',
              message: '信箱已注册',
            });
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      }, 1000);
    },
    loginFormSubmitHandler() {
      setTimeout(() => {
        this.$store
          .dispatch('findAccount', {
            mail: this.loginFormData.email,
            password: this.loginFormData.password,
          })
          .then(() => {
            this.$MSG.success('登入成功 欢迎回来');
            this.$store.commit('setLoginData', {
              isLogin: true,
              loginMail: this.loginFormData.email,
            });
            this.$router.replace({ name: 'MyProfile' });
          })
          .catch((error) => {
            this.$notify.error({
              title: '登入失败',
              message: error,
            });
          })
          .finally(() => {
            this.$store.commit('setLoading', false);
          });
      }, 1000);
    },
    forgotFormSubmitHandler() {
      setTimeout(() => {
        this.forgotFormData.email = '';
        this.$notify({
          title: '請求成功',
          message: '請至信箱收信',
          type: 'success',
        });
        this.$store.commit('setLoading', false);
      }, 1000);
    },
  },
}
</script>

<style lang="scss">
  #Login {
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background: #3e64c3 0% 0% no-repeat padding-box;
      border: 1px solid #e0e0e0;
    }
    .el-checkbox__label {
      color: #000000;
      font-size: 0.875rem;
    }
    .el-form-item__label {
      font-size: 1.25rem;
      color: #000000;
      padding: 0;
    }
    .el-input {
      background: #fafafa 0% 0% no-repeat padding-box;
      border-radius: 5px;
    }
    .el-form-item__error {
      font-size: 0.875rem;
      color: #e52c2c;
      display: flex;
      margin-top: 5px;
      &::before {
        content: '!';
        display: block;
        width: 14px;
        height: 14px;
        font-size: 10px;
        color: white;
        text-align: center;
        background-color: #e52c2c;
        border-radius: 10px;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  #Login {
    background: url('~@/assets/base/BG.png');
    background-position: center;
    .MainContainer {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      @include setMaxWidth;
      margin: 0 auto;
    }
    .loginForm {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 6px #000000cc;
      border-radius: 25px;
      max-width: 560px;
      width: 95%;
      margin: 100px auto 220px auto;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 50px;
      padding-top: 0px;
      padding-bottom: 20px;
      @media screen and(max-width:768px) {
        margin: 35px 16px 64px 16px;
        padding: 30px;
        padding-top: 0px;
      }
      .row {
        width: 100%;
        height: fit-content;
        display: flex;
      }
      .LogoRow {
        margin: 40px auto;
        justify-content: center;
        @media screen and(max-width:768px) {
          margin: 25px auto;
        }
        img {
          display: block;
          width: 190px;
          // height: 68px;
          @media screen and(max-width:768px) {
            width: 111px;
            // height: 40px;
          }
        }
      }
      .LoginTitleRow {
        font-size: 2rem;
        font-weight: bold;
        color: #153892;
        justify-content: center;
      }
      .formCSS {
        width: 100%;
        .el-form-item__label {
          font-size: 1.25rem;
        }

        .LoginBtn,
        .createAccountBtn {
          background: transparent linear-gradient(180deg, #4369c9 0%, #153892 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #213e91;
          border-radius: 10px;
          width: 100%;
          height: 65px;
          line-height: 65px;
          text-align: center;
          font-size: 1.5rem;
          margin: 25px 0;
          cursor: pointer;
          @media screen and(max-width:768px) {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            margin: 17px 0;
          }
          &:hover {
            background: transparent linear-gradient(180deg, #5b7bcc 0%, #284692 100%) 0% 0%
              no-repeat padding-box;
          }
        }
        .LoginBtn {
          &:first-child {
            margin-top: 10px;
          }
        }
        .createAccountBtn {
          background: transparent linear-gradient(180deg, #f58b5a 0%, #e25311 100%) 0% 0% no-repeat
            padding-box;
          border: 1px solid #e25311;
          &:hover {
            background: transparent linear-gradient(180deg, #f5a17b 0%, #db6833 100%) 0% 0%
              no-repeat padding-box;
          }
        }
        .infoBlock {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          color: #000;
          .itemRow {
            width: 33%;
            text-align: center;
            font-size: 1.25rem;
            white-space: nowrap;
            &:last-child {
              display: flex;
              justify-content: flex-end;
            }
            .grayBlock {
              width: 80%;
              height: 2px;
              background: #bebebe 0% 0% no-repeat padding-box;
              @media screen and(max-width:768px) {
                width: 70%;
              }
            }
          }
        }
        .RegInfoBlock {
          .infoText {
            color: #000000;
            font-size: 1.25rem;
            @media screen and(max-width:768px) {
              font-size: 15px;
              line-height: 20px;
            }
          }
          .lineBlock {
            width: 100%;
            height: 1px;
            background-color: #bebebe;
            margin-top: 10px;
            margin-bottom: 15px;
          }
          .tipBlock {
            color: #395fbe;
            font-size: 1rem;
            font-weight: bold;
            cursor: pointer;
          }
        }
        .PWDBlock {
          position: relative;
          .forgetPWD {
            color: #395fbe;
            position: absolute;
            right: 0;
            top: -38px;
            cursor: pointer;
            font-size: 1rem;
          }
        }
        .isAlwaysLogin {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
